import React, { Component } from 'react';
import logo from './img/geotermal_logo.webp'

class Top extends Component {
    render() {
        return (
            <div class="Container fixed-top mbg">
                <div class="row text-center m-3">
                    <div class="col-lg-1 col-4">
                        <a href="#f">
                            <img
                                src={logo}
                                height="70"
                                width="70"
                                className="d-inline-block align-center"
                                alt="Logo"
                            />
                        </a>
                    </div>
                    <div class="col-lg-8 mlink d-none d-lg-inline m-auto">
                        <a href="#ochsner">Pompe de Caldura</a>
                        <a href="#meltem">Recuperatoare</a>
                        <a href="#popodusche">Bideu electric</a>
                        <a href="#geohome">Tocatoare</a>
                    </div>
                    <div class="col-lg-3 col-8 mlink">
                        <h5><a href="tel:+37379939333">(+373) 799 39 333</a></h5>
                        <h5><a href="mailto:info@geotermal.md">info@geotermal.md</a></h5>
                    </div>
                </div>
                <hr class="mlinet" />
            </div>

        );
    }
}

export default Top;